<script>
export default {
    data() {
        return {
            loading: true,
            arena : {
                found: false,
                name: "",
                town: "",
                url: "",
            },
            countDown : 14,
            timer: "",
        }
    },

    mounted() {
        this.getLocation();
    },

    methods: {
        getLocation: function() {
            axios.post(route('services.location.get'))
              .then(response => {
                if ( response.data.success === false ){
                    this.setLocation();
                }
              })
              .catch( error => {
                    this.setLocation();
              });
        },

        setLocation: function(){
            let self = this;
            self.$refs.locationModal.open();
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function(position) {
                    //success
                    console.log(position);
                    axios.post(route('services.location.set'), {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                        locale: appLocale
                      })
                      .then(function (response) {
                        if ( response.data.success === true ){
                            self.arena.found = true;
                            self.arena.town = response.data.arena_town;
                            self.arena.name = response.data.arena_name;
                            self.arena.url = response.data.arena_url;
                            self.$refs.locationModal.open();
                            self.countDownTimer();
                            self.timer = setTimeout( function() {
                                window.location.href = self.arena.url
                            } , 14000);
                        }
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                },function(){
                    //error
                    axios.post(route('services.location.set', {locale: appLocale}))
                      .then(function (response) {
                        if ( response.data.success === true ){
                            self.arena.found = true;
                            self.arena.town = response.data.arena_town;
                            self.arena.name = response.data.arena_name;
                            self.arena.url = response.data.arena_url;
                            self.$refs.locationModal.open();
                            self.countDownTimer();
                            self.timer = setTimeout( function() {
                                window.location.href = self.arena.url
                            } , 14000);
                        }
                      })
                      .catch(function (error) {
                        console.log(error);
                      });
                },{timeout:5000});
            }
        },
        countDownTimer: function() {
            if(this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    this.countDownTimer()
                }, 1000)
            }
        },

        closeModal: function() {
            this.$refs.locationModal.close();
            this.clearTime();
        },

        clearTime: function() {
            clearTimeout(this.timer);
            document.body.style.height = null;
            document.body.style.overflow = null;
        }
    }
}
</script>
