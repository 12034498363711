<script>
import globals from '../../../../global';
export default {
    data() {
        return {
            errors: {},
            simple_pay_form_action: "",
            simple_pay_agree: false,
            formFields: {},
            loading: true,
        }
    },

    mounted() {
        this.loading = false;
    },

    watch: {

    },

    computed: {
    },

    methods: {
        clearErrors: function() {
            this.errors = {};
        },

        submitForm: function() {
            let self = this;
            this.loading = true;
            this.clearErrors();

            axios.post(route('services.reservation.startsimplepay'), {
                agree: this.simple_pay_agree,
                language: globals.locale
            })
            .then(response => {
                if( response.data.redirect_url ){
                    window.location.href = response.data.redirect_url;
                }
            }).catch(error => {
                console.log(error);
                this.loading = false;
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                }
            }).then(response => {
                this.loading = false;
            });
        },
    }
}
</script>
