<script>
export default {
    data() {
        return {
            loading: false,
            formFields: {
                first_name: "",
                last_name: "",
                email: "",
                email_confirmation: "",
                phone: "",
                password: "",
                password_confirmation: "",
                privacy_policy: "",
                terms_and_conditions: "",
                newsletter: 0,
            },
            errors: {},
        }
    },

    mounted() {
    },

    methods: {
        submitForm: function() {
            this.loading = true;
            this.formFields['g-recaptcha-response'] = document.getElementById('g-recaptcha-response').value;
            axios.post(this.$refs.form.action, this.formFields).then(response => {
                if( response.data.redirect_url ){
                    window.location.href = response.data.redirect_url;
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                    this.resetRecaptcha();
                    this.$nextTick(() => {
                        let headerHeight = this.$parent.$refs.stickyHeader.clientHeight;
                        this.$scrollTo('.invalid-feedback', 1000, {offset: -(80+headerHeight)});
		            });
                }
                this.loading = false;
            }).then(response => {
                this.resetRecaptcha();
                this.loading = false;
            });
        },
        resetRecaptcha: function() {
            grecaptcha.reset();
        },
    }

}
</script>
