<script>
import globals from '../../../global';
export default {
    data() {
        return {
            companyClass: 'd-none',
            errors: {},
            loading: true,
            personClass: '',
            user: {},
            reservations: {},
            reservation_links: {},
            reservation_meta: {},
            success: false,
            message: '',
        }
    },

    mounted() {
        this.getUser();
        this.getReservations();
    },

    methods: {
        changeBillingType() {
            this.companyClass = this.companyClass == 'd-none' ? '' : 'd-none';
            this.personClass = this.personClass == 'd-none' ? '' : 'd-none';
            this.getUser();
            this.clearErrors();
        },
        clearErrors() {
            this.errors = {};
            this.success = false;
        },
        getUser() {
            axios.post(route(globals.locale + '.user-dashboard.user.get')).then(response => {
                this.user = response.data.data;
            }).catch(error => {
                this.loading = false;
            }).then(response => {
                this.loading = false;
            });
        },
        getReservations() {
            this.loading = true;
            axios.post(route(globals.locale + '.user-dashboard.reservations.get')).then(response => {
                this.reservations = response.data.data;
                this.reservation_links = response.data.links;
                this.reservation_meta = response.data.meta;
                this.loading = false;
            }).catch(error => {
                this.loading = false;
            }).then(response => {
                this.loading = false;
            });
        },
        loadMoreReservations: function() {
            axios.post(this.reservation_links.next).then(response => {
                this.reservations = this.reservations.concat(response.data.data);
                this.reservation_links = response.data.links;
                this.reservation_meta = response.data.meta;
                this.loading = false;
            }).catch(error => {
                this.loading = false;
            }).then(response => {
                this.loading = false;
            });
        },
        submitForm(formType) {
            let height = document.getElementById('header').clientHeight + document.querySelectorAll('#' + formType + ' .form-group')[0].clientHeight + 16;
            this.loading = true;
            this.user['form_type'] = formType;
            this.clearErrors();
            axios.post(route(globals.locale + '.user-dashboard.user.save'), this.user).then(response => {
                if( response.data.success == true ){
                    this.success = true;
                    this.message = response.data.message;
                    this.errors = {};
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                    this.$nextTick(() => {
                        this.$scrollTo('.invalid-feedback', 1000, {offset: -height});
		            });
                }
                this.loading = false;
            }).then(response => {
                this.loading = false;
            });
        },
        switchTab() {
            this.getUser();
            this.clearErrors();
        }
    }
}
</script>
