<script>
import Datepicker from 'vuejs-datepicker';
import {en, hu} from 'vuejs-datepicker/dist/locale';
import globals from '../../../../global';
export default {
    props : ['arena','package'],
    data() {
        return {
            loading: {
                timeSlots : false,
                reservation : false,
            },
            closed: false,
            en: en,
            hu: hu,
            hoursToSelect : 3,
            selectedDate : "",
            selectedPackageObj: {},
            packages: [],
            actualArena: {

            },
            fetchedTimes: [],
            choosableTimes:[],
            form: {
                package: null,
                number_of_players : null,
                date: null,
                time: [],
                message: "",
            },
            disabledDates: {
                to: this.$moment().toDate(),
                days: this.selectedPackageObj ? this.selectedPackageObj.unavailable_on : [],
                dates : [],
            },
        }
    },

    computed: {
        selectedDateString: function() {
            return this.$moment(this.selectedDate).format("MMMM D, dddd");
        },

        showTimePicker: function() {
            if(this.closed) {
                return false;
            }
            else if(this.selectedDate && !this.loading.timeSlots) {
                return true;
            }
        },

        numberOfPlayersAllowed: function() {
            let allowedPlayersArray = [];
            if( this.selectedPackageObj )
            {
                var i;
                for (i = parseInt(this.selectedPackageObj.minimum_players); i <= parseInt(this.actualArena.maximum_players); i++) {
                    allowedPlayersArray.push(i);
                }
            }
            return allowedPlayersArray;
        },

        timeSelected: function() {
            if(_.find(this.choosableTimes, ['selected', true])) {
                return true;
            }
            return false;
        },

        selectedTimes: function() {
            return _.filter(this.choosableTimes, ['selected', true]);
        },

        selectedInterval: function() {
            var intervalString = '';
            var startTime = this.selectedTimes[0].label;
            if(this.selectedTimes.length == 1) {
                var lastItem = this.selectedTimes[0];
            } else {
                var lastItem = this.selectedTimes.slice(-1)[0];
            }
            var lastTime = lastItem.label;
            var momentObj = this.$moment(this.selectedDate);
            var hourAndMinute = lastTime.split(":");
            momentObj.hour(hourAndMinute[0]);
            momentObj.minute(hourAndMinute[1]);
            var lastTimePlusOneHour = momentObj.add(1, 'h');
            intervalString = startTime + ' - ' + lastTimePlusOneHour.format('HH:mm');
            return intervalString;
        },

        price: function() {
            if(this.selectedPackageObj.price_per_player && this.form.number_of_players) {
                return this.selectedPackageObj.price_per_player * this.form.number_of_players;
            }
            return null;
        }

    },

    watch: {
        'form.package': function(value) {
            this.clearSelections();
            this.configureChoosableTimes();
            var packageID = parseInt(value);
            var index = this.packages.map(function(o) { return o.id; }).indexOf(packageID);
            this.selectedPackageObj = this.packages[index];
            if( !_.includes(this.numberOfPlayersAllowed, this.form.number_of_players) ){
                this.form.number_of_players = this.numberOfPlayersAllowed[0];
            }
            this.disabledDates.days = _.concat(this.actualArena.closedDays,this.selectedPackageObj.unavailable_on);

            if( _.includes(this.disabledDates.days, this.$moment(this.selectedDate).day()) ){
                this.selectedDate = '';
            }

            this.fetchTimeSlots();
        },

        'selectedDate': function(value) {
            this.clearSelections();
            this.form.date = this.$moment(this.selectedDate).format("YYYY-MM-DD");
            this.fetchTimeSlots();
        }
    },

    created() {
        this.init();
    },

    methods: {

        clearSelections: function() {
            _.forEach(this.choosableTimes, function(value) {
                if(value.selected === true){
                    value.selected = false;
                }
            });
        },

        configureChoosableTimes: function() {
            let self = this;
            let timeDuration = this.selectedPackageObj.game_duration;
            _.forEach(this.choosableTimes, function(value,index) {
                if(value.disabled === true && timeDuration > 1 ){
                }
            });
        },

        init: function() {
            this.getArena();
            this.getPackages();
        },

        getPackages: function(){
            return axios.post(route(globals.locale + '.services.packages.get', this.arena))
                .then(response => {
                    this.packages = response.data.data;
                    if(this.package) {
                        this.selectPackage(this.package)
                    }
                    else {
                        this.selectPackage(this.packages[0].id);
                    }
                    this.preSelectForm();
                }).catch(error => {
                    console.log(error);
                }).then(response => {
            });
        },

        getArena: function() {
            var self = this;
            return axios.post(route('services.arena.get', this.arena))
                .then(response => {
                    this.actualArena = response.data.data;
                    if(response.data.data.closed) {
                        _.forEach(response.data.data.closed, function(value) {
                            self.disabledDates.dates.push(new Date(value.date));
                        });
                    }
                }).catch(error => {
                    console.log(error);
                }).then(response => {
            });
        },

        selectPackage: function(id) {
            this.form.package = id;
        },

        selectTime: function(time,index,event) {
            let self = this;
            //let gameDuration = parseInt(this.selectedPackageObj.game_duration);
            let gameDuration = parseInt(this.selectedPackageObj.game_duration + this.selectedPackageObj.additional_free_hours);

            if(time.choosable == true && time.not_choosable == false) {
                if(time.selected || _.find(this.choosableTimes, ['selected', true])) {
                    this.clearSelections();
                }
                let i;
                let actualDate = self.form.date + 'T' + index + ':00';
                let actualMoment = this.$moment(actualDate);

                self.choosableTimes[index].selected = true;
                if(gameDuration > 1) {
                    for(i=1; i<gameDuration; i++)
                    {
                        let keyToSelect = actualMoment.add(1, 'hours').format('HH:mm');
                        self.choosableTimes[keyToSelect].selected = true;
                    }
                }
            }
        },

        fetchTimeSlots: function() {
            var self = this;
            if(this.form.date) {
                this.loading.timeSlots = true;
                this.closed = false;
                axios.post(route('services.timeslots.get'),{
                    arena : this.arena,
                    date: this.form.date,
                    players: this.form.number_of_players,
                    duration: this.form.hoursToSelect,
                    package: this.form.package
                }).then(response => {
                    if(response.data.closed === true){
                        this.closed = response.data.closed;
                    } else {
                            this.choosableTimes = response.data;
                            this.fetchedTimes = response.data;
                    }
                }).catch(error => {
                    console.log(error);
                }).then(response => {
                    this.loading.timeSlots = false;
                });
            }

        },

        getPackage: function(event){
            axios.post(route('services.package.get'),{
                package : event.target.value
            }).then(response => {
                this.package = response.data;
                this.disabledDates.days = this.disabledWeekDays();
            }).catch(error => {
                console.log(error);
            }).then(response => {

            });
        },

        preSelectForm: function() {
            let self = this;
            return axios.post(route('services.reservation.getSession'))
            .then(response => {
                if(response.data.request && response.data.request.arena == parseInt(this.arena) && (this.package !== '' && response.data.request.package == parseInt(this.package))) {
                    self.selectedDate = response.data.request.date;
                    self.form.message = response.data.request.message;
                    self.form.number_of_players = response.data.request.number_of_players;
                    self.selectPackage(response.data.request.package);
                    _.forEach(response.data.request.times, function(value,key) {
                        let selectKey = value.label;
                    });
                } else {
                    this.clearSessions();
                }
            }).catch(error => {
                console.log(error);
            }).then(response => {
                console.log(response);
            });
        },

        startReservation: function() {
            let self = this;
            this.loading.reservation = true;
            axios.post(route('services.reservation.start'),{
                arena : parseInt(self.arena),
                package : self.form.package,
                number_of_players : self.form.number_of_players,
                date: self.form.date,
                times: self.selectedTimes,
                message: self.form.message,
                locale: globals.locale,
            }).then(response => {
                if( response.data.redirect_url ){
                    window.location.href = response.data.redirect_url;
                }
                this.loading.reservation = false;
            }).catch(error => {
                console.log(error);
                this.loading.reservation = false;
            }).then(response => {
                this.loading.reservation = false;
            });
        },

        clearSessions: function() {
            axios.post(route('services.reservation.clearsessions'))
            .then(response => {
            }).catch(error => {
                console.log(error);
            }).then(response => {
            });
        },
    },
    components: {
        Datepicker
    }
}
</script>
