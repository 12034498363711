require('./bootstrap');
import globals from './global';
import 'jquery-match-height';
var moment = require('moment');
moment.locale(globals.locale);

window.Vue = require('vue');
window.Vue.prototype.$moment = moment;

window.VueEvent = new class {
    constructor() {
        this.vue = new Vue();
    }

    fire(event, data = null) {
        this.vue.$emit(event, data);
    }

    listen(event, callback) {
        this.vue.$on(event, callback);
    }
}
require('./plugins/jquery.fancybox.min.js');
import VueScrollTo from 'vue-scrollto';
import debounce from 'lodash/debounce';
import slick from 'slick-carousel';
import Datepicker from 'vuejs-datepicker';
import * as VueGoogleMaps from 'vue2-google-maps'
import { SweetModal, SweetModalTab } from 'sweet-modal-vue'
Vue.component('SweetModal', SweetModal);
Vue.component('SweetModalTab', SweetModalTab);


Vue.use(VueGoogleMaps, {
    load: {
        key: globals.googlemap_api_token.content,
    },
  })
Vue.use(VueScrollTo);


Vue.component('homepage', require('./components/frontend/pages/Homepage.vue').default);
Vue.component('registration-form', require('./components/frontend/pages/RegistrationForm.vue').default);
Vue.component('logout-button', require('./components/frontend/LogoutButton.vue').default);
Vue.component('user-profile', require('./components/frontend/pages/UserProfile.vue').default);
Vue.component('contact-form', require('./components/frontend/pages/ContactForm.vue').default);
Vue.component('reservation-form', require('./components/frontend/pages/reservation/ReservationForm.vue').default);
Vue.component('billing-form', require('./components/frontend/pages/reservation/BillingForm.vue').default);
Vue.component('payment-form', require('./components/frontend/pages/reservation/PaymentForm.vue').default);
Vue.component('choose-payment', require('./components/frontend/pages/reservation/ChoosePayment.vue').default);

let lasercornerApp = new Vue({
    el: '#lasercorner-app',
    data: {
        headerClass: '',
    },

    created() {
        this.handleDebouncedScroll = debounce(this.handleScroll, 100);
        window.addEventListener('scroll', this.handleDebouncedScroll);
        if(window.scrollY > 50) {
            this.headerClass = 'header--sticky';
        }
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        handleScroll(event) {
            this.headerClass = window.scrollY > 50 ? 'header--sticky' : '';
        }
    }
});

// JQuery stuff
$('.property-box-carousel').slick({
    infinite: true,
    slidesToShow: 4,
    autoplay: true,
    dots: false,
    arrows: true,
    responsive: [
        {
            breakpoint: 1201,
            settings: {
                slidesToShow: 3,
            }
        }, {
            breakpoint: 992,
            settings: {
                slidesToShow: 2,
            }
        }, {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
            }
        }
    ]
});

$('.testimonial-carousel').slick({
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    dots: false,
    arrows: true,
    fade: true,
    cssEase: 'linear',
});

$('.text-carousel').slick({
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    dots: true,
    arrows: true,
    fade: true,
    cssEase: 'linear',
});

// Plus/minus input
var $inputNumber = $('.input-number');
$inputNumber.each(function() {
    var $this            = $(this),
        input            = $this.find('input'),
        inputNumberValue = parseInt( input.val() ),
        minValue         = parseInt( input.data('min') ),
        maxValue         = parseInt( input.data('max') );

    $this.find('.input-number__minus').on('click', function(e){
        e.preventDefault();

        if(inputNumberValue > minValue) {
            $(this).closest('.input-number').find('input').val(--inputNumberValue);
        }
    });

    $this.find('.input-number__plus').on('click', function(e){
        e.preventDefault();

        if(inputNumberValue < maxValue) {
            $(this).closest('.input-number').find('input').val(++inputNumberValue);
        }
    });
});

// Toggle Button
$('[data-btn-toggle]').on('change', 'input', function() {
    var $this = $(this);
    var sections = $this.closest('[data-btn-toggle]').data('btn-toggle').split(',');
    var section1 = $('#' + sections[0]);
    var section2 = $('#' + sections[1]);


    if($this.is(':checked')) {
        section1.addClass('d-none');
        section2.removeClass('d-none');
    } else {
        section1.removeClass('d-none');
        section2.addClass('d-none');
    }
});

if (typeof fancybox !== 'undefined' && $.isFunction(fancybox)) {
    $('[data-fancybox]').fancybox({
        protect: true,
        arrows: true,
        buttons: [
            'close'
        ]
    });
}

var $window = $(window),
$body = $('body'),
$header = $('.header');

// Menu

$body.append('<a href="#" class="mobile-menu__close"></a>');

$('.mobile-menu__btn').on('click', function(e) {
    e.preventDefault();

    $body.addClass('menu--active');
    $('.mobile-menu__close').addClass('mobile-menu__close--active');
});

$body.on('click', '.mobile-menu__close', function(e) {
    e.preventDefault();

    $body.removeClass('menu--active');
    $('.mobile-menu__close').removeClass('mobile-menu__close--active');
});

$body.on('click', '.menu__profile__avatar', function(e) {
    e.preventDefault();

    $('li.menu__profile ul').toggleClass('open');
});
