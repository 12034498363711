<template>
    <form action="/logout" method="post" ref="logout" @submit.prevent="logout">
        <input type="hidden" name="_token" :value="csrf" />
        <li><a href="#" @click.prevent="logout()">{{trans.logout}}</a></li>
    </form>
</template>

<script>
export default {
    data() {
        return {
            csrf: "",
            trans:trans
        }
    },
    created() {
        let token = document.head.querySelector('meta[name="csrf-token"]');
        this.csrf = token.content;
    },
    methods: {
        logout() {
            this.$refs.logout.submit();
        }
    }
}
</script>
