<script>
import globals from '../../../../global';
export default {
    props : ['type'],
    data() {
        return {
            errors: {},
            formFields: {},
            loading: true,
        }
    },

    mounted() {
        this.loading = false;
    },

    watch: {
    },

    computed: {
    },

    methods: {
        clearErrors: function() {
            this.errors = {};
        },

        submitForm: function() {
            let self = this;
            this.loading = true;
            this.clearErrors();
            axios.post(route('services.reservation.validate'), {
                type: this.type,
                language : globals.locale
            })
            .then(response => {
                if( response.data.redirect_url ){
                    window.location.href = response.data.redirect_url;
                }
                this.loading = false;
            }).catch(error => {
                console.log(error);
                this.loading = false;
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                    this.$nextTick(() => {
		            });
                }
            }).then(response => {
                this.loading = false;
            });

        },
    }
}
</script>
