<script>
export default {
    data() {
        return {
            loading: false,
            formFields: {
                name: "",
                email: "",
                phone: "",
            },
            errors: {},
        }
    },

    mounted() {
        this.getUser();
    },

    methods: {
        getUser: function() {
            axios.post(route('services.user.get')).then(response => {
                if( response.data.data.length > 0 )
                {
                    this.formFields = response.data.data;
                }
            }).catch(error => {
                this.loading = false;
            }).then(response => {
                this.loading = false;
            });
        },
        submitForm: function() {
            this.loading = true;
            this.formFields['g-recaptcha-response'] = document.getElementById('g-recaptcha-response').value;
            axios.post(this.$refs.form.action, this.formFields).then(response => {
                if( response.data.redirect_url ){
                    window.location.href = response.data.redirect_url;
                }
            }).catch(error => {
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                    this.resetRecaptcha();
                    this.$nextTick(() => {
                        let scrollToElement = document.querySelector('.invalid-feedback');
                        let headerHeight = document.getElementById('header').offsetHeight;
                        let formGroupHeight = document.querySelector('.contact-form .form-group').offsetHeight;
                        this.$scrollTo(scrollToElement, 1000, {offset: -(headerHeight+formGroupHeight)});
		            });
                }
                this.loading = false;
            }).then(response => {
                this.resetRecaptcha();
                this.loading = false;
            });
        },
        resetRecaptcha: function() {
            grecaptcha.reset();
        },
    }

}
</script>
