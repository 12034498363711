<script>
import globals from '../../../../global';
export default {
    data() {
        return {
            companyClass: 'd-none',
            coupon_code: "",
            couponErrors: {},
            couponLoading: false,
            errors: {},
            formFields: {
                accept_rules: false,
                additionalElements: {},
                company : false,
                coupon_code: '',
                message: '',
                privateEvent: false,
                user: {},
                registerRequest: false,
            },
            loading: true,
            loggedIn: false,
            loginErrors: {},
            loginLoading: false,
            personClass: '',
            pizzaClass: 'd-none',
            request: {},
            reservation: {
                price: null,
                coupon_used: null
            },
            success: false,
        }
    },

    mounted() {
        this.getUser();
        this.getAdditionalElements();
        this.getReservationDetails();
    },

    watch: {
    },

    computed: {
        showLogin: function() {
            if(this.loggedIn) {
                return false;
            }
            return true;
        },

        showPlayLevel: function() {
            if(this.formFields.privateEvent) {
                return false;
            } else if (this.reservation.open_game) {
                return true;
            }
            return false;
        },

        finalPrice: function() {
            let self = this;
            let reservationPrice = this.reservation.price;
            let finalPrice = reservationPrice;
            _.forEach(self.formFields.additionalElements, function(value, key) {
                if(value.elements && value.elements.length > 0 && value.selected_id && value.selected === true) {
                    let elementKey = _.findKey(value.elements, { 'id': value.selected_id, });
                    if (elementKey) {
                        if(value.included_in_package === false) {
                            let additionalPrice = value.elements[elementKey].pivot.price * value.quantity;
                            finalPrice += additionalPrice;
                        }
                    }
                }
            });
            if(_.has(self.reservation.coupon_used, 'price_discount')) {
                console.log(self.reservation.coupon_used.price_discount);
                finalPrice -= self.reservation.coupon_used.price_discount;
            }
            if(_.has(self.reservation.coupon_used, 'price_percent_discount')) {
                console.log(self.reservation.coupon_used.price_percent_discount);
                var discount = (finalPrice * self.reservation.coupon_used.price_percent_discount)/100;
                finalPrice -= discount;
            }
            return finalPrice;
        }
    },

    methods: {
        changeBillingType: function() {
            this.companyClass = this.companyClass == 'd-none' ? '' : 'd-none';
            this.personClass = this.personClass == 'd-none' ? '' : 'd-none';
            this.getUser();
            this.clearErrors();
        },

        clearErrors: function() {
            this.errors = {};
            this.success = false;
        },

        decreaseCategory: function(item) {
            if(item.category.pivot.price_included == 0 && item.category.pivot.item_number_allowed == 1) {
                if(item.quantity>1) {
                    item.quantity--;
                }
            }

        },

        elementsClass: function(item) {
            if(item.selected || item.showElements) {
                return '';
            }
            return 'd-none';
        },

        getAdditionalElements: function() {
            axios.post(route('services.reservation.getAdditionalElements'), {
                locale : globals.locale
            })
            .then(response => {
                this.formFields.additionalElements  = response.data;
            }).catch(error => {
                this.loading = false;
            }).then(response => {
                this.loading = false;
            });
        },

        getUser: function() {
            axios.post(route('services.user.get')).then(response => {
                if(!_.isEmpty(response.data.data)) {
                    this.formFields.user  = response.data.data;
                    this.loggedIn = true;
                } else {
                    this.formFields.user = {}
                }
            }).catch(error => {
                this.loading = false;
            }).then(response => {
                this.loading = false;
            });
        },

        getReservationDetails: function() {
            axios.post(route('services.reservation.getSession')).then(response => {
                this.request = response.data.request;
                this.reservation = response.data.reservation;
                this.formFields.privateEvent = response.data.private_event_allowed;
            }).catch(error => {
                this.loading = false;
            }).then(response => {
                this.loading = false;
            });
        },

        increaseCategory: function(item) {
            if(item.category.pivot.price_included == 0 && item.category.pivot.item_number_allowed == 1) {
                item.quantity = item.quantity + 1;
            }
        },

        loginUser: function() {
            let self = this;
            this.loginLoading = true;
            axios.post(this.$refs.loginURL.value, {
                email : self.$refs.loginEmail.value,
                password : self.$refs.loginPassword.value,
            }).then(response => {
                console.log(response);
                if(response.data.user) {
                    let token = document.head.querySelector('meta[name="csrf-token"]');
                    token.content = response.data.token;
                    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
                    this.formFields.user  = response.data.user;
                    this.loggedIn = true;
                    this.loading = false;
                    self.$refs.loginModal.close();
                }
                this.loginLoading = false;
            }).catch(error => {
                console.log(error);
                this.loginLoading = false;
                if (error.response.status && error.response.status === 422) {
                    this.loginErrors = error.response.data.errors || {};
                    this.$nextTick(() => {
		            });
                }
            }).then(response => {
                this.loginLoading = false;
            });
        },

        submitForm: function() {
            let self = this
            this.loading = true;
            this.clearErrors();

            axios.post(route('services.reservation.savedata'),{
                locale : globals.locale,
                formFields : self.formFields,
                requestObj : self.request,
                reservationObj : self.reservation,
            })
            .then(response => {
                if( response.data.redirect_url ){
                    window.location.href = response.data.redirect_url;
                }
                this.loading = false;
            }).catch(error => {
                console.log(error);
                this.loading = false;
                if (error.response.status === 422) {
                    this.errors = error.response.data.errors || {};
                    this.$nextTick(() => {
                        let scrollToElement = document.querySelector('.invalid-feedback');
                        let headerHeight = document.getElementById('header').offsetHeight;
                        let formGroupHeight = document.querySelector('#payment-container .form-group').offsetHeight;
                        this.$scrollTo(scrollToElement, 1000, {offset: -(headerHeight+formGroupHeight)});
		            });
                }
            }).then(response => {
                console.log(response);
                this.loading = false;
            });
        },

        openLoginModal: function() {
            this.loginErrors = {};
            this.$refs.loginModal.open();
            this.$nextTick(() => {
                this.$refs.loginEmail.focus();
            });
        },

        openCouponModal: function() {
            this.couponErrors = {};
            this.$refs.couponModal.open();
            if(!this.coupon_used) {
                this.$nextTick(() => {
                    this.$refs.couponEmail.focus();
                });
            }
        },

        redeemCoupon: function() {
            let self = this
            this.couponLoading = true;
            this.couponErrors = {};
            axios.post(route('services.reservation.redeemcoupon'),{
                locale : globals.locale,
                code : self.$refs.couponCode.value,
                requestObj : self.request
            })
            .then(response => {
                console.log(response);
                if(response.data.success === true) {
                    this.reservation.coupon_used = response.data.coupon;
                }
                this.getReservationDetails();
                this.couponLoading = false;
            }).catch(error => {
                console.log(error);
                this.couponLoading = false;
                if (error.response.status === 422) {
                    this.couponErrors = error.response.data.errors || {};
                }
            }).then(response => {
                this.couponLoading = false;
            });
        },

        closeCouponModal: function() {
            this.couponErrors = {};
            this.$refs.couponModal.close();
        },

        switchTab: function() {
            this.getUser();
            this.clearErrors();
        },

        toggleClass: function(element) {
            let variableName = eval(element+'Class');
            console.log(variableName);
        },

        acceptRules: function() {
            this.$refs.acceptRules.checked = true;
            this.formFields.accept_rules = true;
            this.$refs.rulesModal.close();
        }
    }
}
</script>
